<template>
	<div id="main">
		<van-row>
			<van-col span="24">
				<van-nav-bar title="服务器状态" left-text="返回" right-text="" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
			</van-col>
		</van-row>
		<van-row>
			<van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
				访问著名大型网站，优先使用香港区(百分比越小效果越好)
			</van-notice-bar>
		</van-row>
		<van-row>
			<van-col span="24">
				<van-divider>流量区</van-divider>
			</van-col>
		</van-row>
		<van-row v-for="(item, index) in data" :key="index">
			<van-col span="1">
			</van-col>
			<van-col span="3">
				{{ index }}
			</van-col>
			<van-col span="18">
				<van-progress :percentage=item.numProportion :pivot-text="item.num+'G'" />
			</van-col>
			<van-col span="2">
			</van-col>
		</van-row>
		<van-row>
			<van-col span="24">
				<van-divider>日期区</van-divider>
			</van-col>
		</van-row>
		<van-row v-for="(item, index) in data" :key="index">
			<van-col span="1">
			</van-col>
			<van-col span="3">
				{{ index }}
			</van-col>
			<van-col span="18">
				<van-progress :percentage=item.dataProportion :pivot-text="item.data+'天'" />
			</van-col>
			<van-col span="2">
			</van-col>
		</van-row>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				data: {
				}
			};
		},
		computed: {},
		methods: {
			onClickLeft() {
				window.history.go(-1);
			},
			onClickRight() {},
		},
		created: function() {
			this.$axios.get("/traffic", {
				"id": 123
			}).then(res => {
				console.log(res.data.data);
				this.data = res.data.data;
			});
		}
	};
</script>

<style>
	.custom-title {
		margin-right: 4px;
		vertical-align: middle;
	}

	#home {
		text-align: center;
	}

	.search-icon {
		font-size: 16px;
		line-height: inherit;
	}
</style>
